import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/post-list"
import HeaderImage from "../components/header-image"
import imageMap from "../components/image-map"
import Col2 from "../components/col-2"

const IndexPage = ({ data }) => {
  const { nodes: pickupPosts, totalCount: pickupCount } = data.pickupPosts
  const { nodes: newsPosts, totalCount: newsCount } = data.newsPosts
  const mainImages = imageMap(data.mainImages.nodes)

  return (
    <Layout>
      {/* 空にすると会社名だけになるので意図して `title` を空にしています */}
      <Seo title="" />
      <HeaderImage
        pcImage={{
          src: mainImages['L0_top_P1'],
	  alt: "産業用無人航空機",
        }}
        spImages={[
          {
            src: mainImages['L0_top_S1'],
	    alt: "産業用無人航空機 ドローン",
          },
          {
            src: mainImages['L0_top_S2'],
	    alt: "産業用無人航空機 無人ヘリコプター",
          },
        ]}
      />
      <section>
        <PostList heading="ピックアップ" posts={pickupPosts} totalCount={pickupCount} morePath="/PU/" />
      </section>
      <section>
        <Col2
          left={<section>
            <PostList heading="新着情報" posts={newsPosts} totalCount={newsCount} morePath="/NS/" excerptType="short" />
          </section>}
          right={<div>
            <section>
              <h2 className="hidden">動画</h2>
		<center>
<img src="/uploads/L3_dji_M_C5_ecc5654c25.png"  width="100%"height="auto" alt="AGRAS T10使いやすく初心者にも最適な農業ドローン"/>        
</center>    
	</section>
          </div>}
        />
      </section>
    </Layout>
  )
}

export default IndexPage

// 「ピックアップ」「新着情報」の記事を取得する
export const query = graphql`
  query IndexPageQuery {
    pickupPosts: allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "PU"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
      nodes {
        ...PostTeaser
      }
      totalCount
    }

    newsPosts: allStrapiPost(filter: {fields: {areas: {elemMatch: {machineName: {eq: "NS"}}}}}, sort: {fields: fields___date, order: DESC}, limit: 3) {
      nodes {
        ...PostTeaser
      }
      totalCount
    }

    mainImages: allFile(filter: {relativeDirectory: {eq: "pages/home"}}) {
      nodes {
        ...MainImage
      }
    }
  }
`
